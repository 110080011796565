import styled from "styled-components";


type Props ={
    bg: string
}

const Button = styled.button<Props>`
    border-radius: 10px;
    cursor: pointer;
    padding: 10px;
    border: none;
    background-color: ${props => props.bg};
    margin: 0px;
`

export default Button