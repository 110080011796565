import React from 'react'
import Button from '../components/Button'
import Container from '../components/Container'
import {Link} from 'react-router-dom'

const Home = () => {
  return (
    < Container>
    <Link to="/about">
        <Button bg='pink'>
            Marina é boco
        </Button>
    </Link>
    </Container>
  )
}

export default Home