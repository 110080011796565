import React from 'react'
import Button from '../components/Button'
import Container from '../components/Container'
import {Link} from 'react-router-dom'

const About = () => {
  return (
    < Container>
    <Link to="/">
        <Button bg='pink'>
            Marina continua sendo boco
        </Button>
    </Link>
    </Container>
  )
}

export default About